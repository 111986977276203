import { NumericLiteral } from 'typescript';

export type Dimension = number | string;

export type ChartData = number[];

export type xData = number[] | string[];

export type LanguageType = 'ko' | 'en';

export type ThemeType = 'light' | 'dark';

export interface ChartSeries {
  seriesName: string;
  value: number;
  data: {
    day: number;
    value: NumericLiteral;
  };
  name: string;
}

export type ChartSeriesData = ChartSeries[];

export type Severity = 'success' | 'error' | 'info' | 'warning';

export type ButtonType = 'completed' | 'pending' | 'assigned' | 'button' | 'tag' | 'inform';

export type TwoFactorAuthOption = 'email' | 'phone';

export type ActivityStatusType = 'sold' | 'booked' | 'added';

export enum CurrencyTypeEnum {
  USD = 'USD',
  ETH = 'ETH',
  BTC = 'BTC',
}

export interface PaymentCard {
  cvc: string;
  expiry: string;
  name: string;
  number: string;
  // eslint-disable-next-line
  focused: any;
  background: string;
  isEdit: boolean;
}

export interface Executer {
  id: string;
  name: string;
  email: string;
  category: string;
  authorized: string;
}

export interface BaseYMData {
  category: string;
  data: string;
  code: string;
  id: string;
  owner: string;
  title: string;
}

export interface TargetData extends BaseYMData {
  status: number;
  reservedAt: string;
}

export interface TaskData {
  status: number;
  category: string;
  executers: Executer[];
  group: BaseYMData;
  target: TargetData;
  title: string;
  id: string;
  data: any;
  videoTasks: any;
  executedAt: string;
  completedAt: string;
  createdAt: string;
}

export interface DetailTaskData {
  key: number;
  name: string;
  bDate: any;
  region: string;
  groupName: string;
  executers: Executer[];
  status: number;
  groupId: string;
  taskId: string;
  targetId: string;
  category: string;
  taskData: any;
  executedAt: string;
  completedAt: string;
  createdAt: string;
  rDate: string;
  mailHistories: string[];
  groupMailHistories: { to: string; timestamp: string; key: string; status:number}[];
  groupData: any;
}

export interface GetYMAssessmentsData {
  getYMAssessments: {
    success: boolean;
    tasks: TaskData[];
    error: string | null;
    id: string;
  };
}

export interface GetYMTags {
  getYMTags: {
    id: string;
    success: boolean;
    tags: {
      code: string;
      name: string;
    }[];
    error: string | null;
  };
}

export interface TargetDataParse {
  uid: string;
  name: string;
  bdate: string;
  sex: number;
}

export interface TargetData {
  data: any;
  id: string;
  title: string;
}

export interface GetYMGroupData {
  getYMGroup: {
    success: boolean;

    group: {
      targets: TargetData[];
    };
  };
}

// export interface CommonGQLFields {}

export interface AssessmentData {
  getYMAssessmentsByTargetId: {
    success: boolean;
    tasks: TaskData[];
    error: string | null;
    id: string;
  };
}

export interface YmTargetById {
  getYMTarget: {
    success: boolean;
    error: string | null;
    target: {
      title: string;
      data: any;
      group: {
        title: string;
      };
      reservedAt: string;
    };
  };
}

export interface RadioData {
  cognition: number;
  language: number;
  exercise: number;
  socialEmotional: number;
  interaction: number;
}

export interface SnapData {
  originalScore: string;
  percentile: string;
  standardScore: string;
  level: string;
}

export interface KidsData {
  growthPoint: string;
  level: string;
}

export interface Assess1KidsData {
  largeMuscles: string;
  smallMuscles: string;
  communication: string;
  cognition: string;
  socialInteraction: string;
}

export interface BaseModalProps {
  title: string;
  open: boolean;
  onClose: () => void;
  onOkHandler?: () => void;
  width: string;
}

export interface Roles {
  id: string;
  name: string;
  permissions?: string[];
}

export interface AllYMRoles {
  allYMRoles: Roles[];
}

export interface AllYMTags {
  getYMTags: {
    success: boolean;
    tags: {
      code: string;
      name: string;
    }[];
  };
}
