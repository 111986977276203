import { gql, TypedDocumentNode } from '@apollo/client';
import { GetYMAssessmentsData, GetYMGroupData, TargetDataParse } from '@app/interfaces/interfaces';

// ---------------------------------------------------

export const CREATE_USER = gql`
  mutation CreateUser($email: String!) {
    createUser(email: $email) {
      id
      email
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($password: String!) {
    resetPassword(password: $password) {
      success
    }
  }
`;

export const RESET_CODEM = gql`
  mutation resetCodeM($code: String!) {
    resetCodeM(code: $code) {
      success
    }
  }
`;

export const mocks = [
  {
    request: {
      query: CREATE_USER,
      variables: {
        email: 'example@example.com', // 모의 데이터를 위한 이메일
      },
    },
    result: {
      data: {
        createUser: {
          id: '123', // 사용자의 ID
          email: 'example@example.com', // 사용자의 이메일
        },
      },
    },
  },

  {
    request: {
      query: RESET_CODEM,
      variables: {
        code: '123456',
      },
    },
    result: {
      data: {
        success: true,
      },
    },
  },

  {
    request: {
      query: RESET_PASSWORD,
      variables: {
        password: '123456',
      },
    },
    result: {
      data: {
        success: true,
      },
    },
  },
];

export const allYMTasksGQL = gql`
query AllYMTasks {
  allYMTasks {
    data
    id
    title
    category
    willExecuteAt
    status
  }
}
`;

export const getYMTasksGQL = gql`
query GetYMTasks($category: String!) {
  getYMTasks(category: $category) {
    error
    id
    success
    tasks {
      id
      category
      title
      data
      owner
      willExecuteAt
      status
    }
  }
}
`;

export const getYMTasksByTaskIdGQL = gql`
  query GetYMTasksByTaskId($taskid: ID!) {
    getYMTasksByTaskId(taskid: $taskid) {
      id
      error
      success
      tasks {
        id
        title
        data
      }
    }
  }
`;

export const generateHistoryGQL = gql`
  mutation generateHistory($id: ID!, $input: CardUpdate!) {
    generateHistory(id: $id, input: $input) {
      success
      error
      id
      data
    }
  }
`;
/*
export const getFlowGQL = gql`
query{
  getFlow(
    id : "62bc2a2bbec7bf7236122c6e"
  ){
    id
    flow{
      cards{
        id
        title
        category
        data
      }
    }
    success
    error
  }
}
`;
*/
export const getFlowGQL = gql`
  query getFlow($id: ID!) {
    getFlow(id: $id) {
      id
      flow {
        cards {
          id
          title
          subtitle
          category
          data
          linkto
          img
        }
      }
      success
      error
    }
  }
`;

//write Query for Reactvar
//this query is independent of server graphql

//---------------------------------------------
export const userGQL = gql`
  query UserQuery {
    userData {
      id
      age
      details
      access_date_utc
    }
  }
`;
export const getUserGQL = gql`
  query GetUser($getUserId: ID!) {
    getUser(id: $getUserId) {
      success
      user {
        auths {
          socialid
          loggedAt
          type
          userid
          id
        }
        id
        username
        loggedAt
      }
    }
  }
`;
export const getUserByName = gql`
  query getUserByName($username: String!) {
    getUserByName(username: $username) {
      id
      user {
        id
        username
        flowid
      }
      success
      error
    }
  }
`;

export const getUserByToken = gql`
  query getUserByToken($token: String!) {
    getUserByToken(token: $token) {
      id
      user {
        id
        username
        flowid
        cards {
          category
          title
          subtitle
          data
          linkto
          color
          img
          id
          editable
          source
          shape
        }
        flows {
          id
          category
          title
          editable
          source
        }
      }
      success
      error
    }
  }
`;

export const logIn = gql`
  mutation logIn($username: String!, $password: String!) {
    logIn(username: $username, password: $password) {
      success
      token
      error
      id
    }
  }
`;

export const signUpLoginM = gql`
  mutation signUpLoginM($username: String!, $password: String!) {
    signUpLoginM(username: $username, password: $password) {
      success
      token
      error
      id
    }
  }
`;

export const signUpSocialM = gql`
  mutation SignUpWSocial($email: String!, $username: String!, $socialId: String!, $token: String!, $type: String!) {
    signUpWSocial(email: $email, username: $username, socialId: $socialId, token: $token, type: $type) {
      success
      id
      token
      error
      type
      socialId
    }
  }
`;

export const logInWSocialM = gql`
  mutation LogInWSocial($email: String!, $socialId: String!, $token: String!, $type: String!) {
    logInWSocial(email: $email, socialId: $socialId, token: $token, type: $type) {
      success
      id
      token
      error
      type
      socialId
    }
  }
`;

export const generateUserM = gql`
  mutation generateUserM($username: String!, $password: String!, $userdata: String!) {
    generateUserM(username: $username, password: $password, userdata: $userdata) {
      success
      token
      error
      id
    }
  }
`;

export const getYMGroupListGQL = gql`
  query getYMGroupList($code: String!) {
    getYMGroupList(code: $code) {
      data
      error
      id
      idx
      success
    }
  }
`;

export const updateYMGroupListGQL = gql`
  mutation updateYMGroupList($code: String!, $input: YMGroupListInput!) {
    updateYMGroupList(code: $code, input: $input) {
      data
      error
      id
      success
    }
  }
`;

export const getYMCountGQL = gql`
  query getYMCount($code: String!) {
    getYMCount(code: $code) {
      code
      count
      success
      error
    }
  }
`;
//

export const getAllYMExecutersGQL = gql`
  query getAllYMExecuters {
    allYMExecuters {
      id
      authorized
      name
      data
      createdAt
      user
      email
    }
  }
`;

export const getUsersGQL = gql`
  query allUsers {
    allUsers {
      id
      username
    }
  }
`;

export const getYMExecuterGQL = gql`
  query getYMExecuter($uid: ID!) {
    getYMExecuter(uid: $uid) {
      id
      executer {
        id
        authorized
        category
        name
        data
        status
      }
      success
      error
    }
  }
`;

export const getYMUserGQL = gql`
  query GetYMUser($uid: ID!) {
    getYMUser(uid: $uid) {
      ymuser {
        user
        id
        name
        status
        data
        email
        category
        authorized
      }
      id
      success
      error
    }
  }
`;

export const createYMExecuterGQL = gql`
  mutation createYMExecuter($input: YMExecuterInput!) {
    createYMExecuter(input: $input) {
      error
      executer {
        name
        id
        authorized
        user
      }
      id
      success
    }
  }
`;

export const createYMUserGQL = gql`
  mutation CreateYMUser($input: YMUserInput!) {
    createYMUser(input: $input) {
      success
      error
      id
      ymuser {
        category
        data
        email
        id
        name
        user
      }
    }
  }
`;

export const createYMGroupGQL = gql`
  mutation createYMGroup($input: YMGroupInput!) {
    createYMGroup(input: $input) {
      success
      error
      id
      data
    }
  }
`;

export const getYMGroupsByExecuterGQL = gql`
  query getYMGroupsByExecuter($getYMGroupsByExecuterId: ID!) {
    getYMGroupsByExecuter(id: $getYMGroupsByExecuterId) {
      success
      id
      error
      groups {
        id
        category
        title
        data
        status
        reservedAt
      }
    }
  }
`;

export const getYMGroupByCodeGQL = gql`
  query Query($code: String!) {
    getYMGroupByCode(code: $code) {
      id
      error
      group {
        category
        code
        data
        id
        owner
        reservedAt
        status
        tags
        title
      }
    }
  }
`;

export const getYMGroupGQL = gql`
  query getYMGroup($gid: ID!) {
    getYMGroup(id: $gid) {
      success
      id
      error
      group {
        category
        code
        data
        id
        owner
        reservedAt
        status
        tags
        title
        targets {
          data
          title
          id
        }
      }
    }
  }
`;

/*
export const getYMGroupById: TypedDocumentNode<GetYMGroupData> = gql`
  query getYMGroup($getYmGroupId: ID!) {
    getYMGroup(id: $getYmGroupId) {
      success
      id
      error
      group {
        targets {
          data
          title
          id
        }
      }
    }
  }
`;
*/

export const createYMTargetGQL = gql`
  mutation createYMTarget($input: YMTargetInput!) {
    createYMTarget(input: $input) {
      success
      error
      id
      data
    }
  }
`;

export const getAssessmentByTargetIdGQL = gql`
  query GetYMAssessmentsByTargetId($tgid: ID!, $category: String) {
    getYMAssessmentsByTargetId(tgid: $tgid, category: $category) {
      success
      tasks {
        title
        id
        data
        status
        executers {
          id
          user
          authorized
          name
          email
          category
          data
          targets {
            data
            category
            title
            id
            owner
          }
        }

        target {
          title
          id
          data
          reservedAt
        }

        group {
          id
          owner
          code
          title
          category
          data
        }
        category
        executedAt
      }
      id
    }
  }
`;

export const removeYmTargetFromYMUserM = gql`
  mutation RemoveYMTargetFromYMUser($removeYmTargetFromYmUserId: ID!, $targetid: ID!) {
    removeYMTargetFromYMUser(id: $removeYmTargetFromYmUserId, targetid: $targetid) {
      success
      id
    }
  }
`;

export const getYMTargetsByExecuterGQL = gql`
  query Query($getYMTargetsByExecuterId: ID!) {
    getYMTargetsByExecuter(id: $getYMTargetsByExecuterId) {
      success
      id
      error
      targets {
        id
        category
        title
        data
        status
        reservedAt
      }
    }
  }
`;

export const getYMTargetsByGrGQL = gql`
  query Query($gid: ID!) {
    getYMTargetsByGr(gid: $gid) {
      success
      id
      error
      targets {
        id
        category
        title
        data
        status
        executers {
          name
        }
        reservedAt
      }
    }
  }
`;

export const allYMTargetsGQL = gql`
  query Query {
    allYMTargets {
      id
      category
      title
      data
      status
      executers {
        name
      }
      reservedAt
    }
  }
`;

export const searchYMTargetsByExecutersGQL = gql`
  query Query($keyword: String!) {
    searchYMTargetsByExecuter(keyword: $keyword) {
      targets {
        id
        owner
        status
        title
        tasks {
          id
          data
          status
          title
          category
        }

        executers {
          id
          name
          authorized
        }

        group {
          id
          data
          code
          status
        }
        reservedAt
        category
        data
      }
    }
  }
`;

export const searchYMTargetsByGroupGQL = gql`
  query SearchYMTargetsByGroup($keyword: String!) {
    searchYMTargetsByGroup(keyword: $keyword) {
      targets {
        id
        owner
        status
        title
        tasks {
          id
          data
          status
          title
          category
        }

        executers {
          id
          name
          authorized
        }

        group {
          id
          data
          code
          status
        }
        reservedAt
        category
        data
      }
    }
  }
`;

export const searchYMTargetsByNameGQL = gql`
  query SearchYMTargetsByName($keyword: String!) {
    searchYMTargetsByName(keyword: $keyword) {
      targets {
        id
        owner
        status
        title
        tasks {
          id
          data
          status
          title
          category
        }

        executers {
          id
          name
          authorized
        }

        group {
          id
          data
          code
          status
        }
        reservedAt
        category
        data
      }
    }
  }
`;

// status : 0 - normal, 5 - deleted 
export const getYMTargetsByStatusGQL = gql`
  query Query($status: Int!) {
    getYMTargetsByStatus(status: $status) {
      error
      success
      targets {
        id
        category
        title
        data
        status
        owner
        executers {
          id
          name
          authorized
        }
        tasks {
          id
          data
          status
          title
          category
        }
        group {
          id
          data
          code
          status
        }
        reservedAt
        rtdata
      }
    }
  }
`;

export const getYMTargetsByExAndStatusGQL = gql`
  query Query($eId: ID!, $status: Int!) {
    getYMTargetsByExAndStatus(eid: $eId, status: $status) {
      error
      success
      targets {
        id
        category
        title
        data
        status
        executers {
          id
          name
          authorized
        }
        tasks {
          id
          data
          status
          title
          category
        }
        group {
          id
          data
          code
          status
        }
        reservedAt
      }
    }
  }
`;

export const getYMTargetsByNameAndExAndStatusGQL = gql`
  query Query($name: String!, $eId: ID!, $status: Int!) {
    getYMTargetsByNameAndExAndStatus(name: $name, eid: $eId, status: $status) {
      error
      success
      targets {
        id
        category
        title
        data
        status
        executers {
          id
          name
          authorized
        }
        tasks {
          id
          data
          status
          title
          category
        }
        group {
          id
          data
          code
          status
        }
        reservedAt
      }
    }
  }
`;

export const getYMTargetsByExAndGrGQL = gql`
  query Query($eId: ID!, $gId: ID!) {
    getYMTargetsByExAndGr(eid: $eId, gid: $gId) {
      success
      id
      error
      targets {
        id
        category
        title
        data
        status
        reservedAt
      }
    }
  }
`;

export const getYMTargetGQL = gql`
  query GetYMTarget($tgid: ID!) {
    getYMTarget(id: $tgid) {
      success
      target {
        id
        title
        category
        data
        reservedAt
        owner
        tasks {
          id
          data
          category
          status
        }
        group {
          id
          title
          code
          data
          status
        }
        executers {
          id
          name
          authorized
        }  
      }
    }
  }
`;

export const createYMTaskGQL = gql`
  mutation createYMTask($input: YMTaskInput!) {
    createYMTask(input: $input) {
      success
      error
      id
      data
    }
  }
`;

export const getYMTaskGQL = gql`
  query getYMTask($id: ID!) {
    getYMTask(id: $id) {
      id
      task {
        id
        category
        title
        data
        status
      }
      success
      error
    }
  }
`;

export const getYMTasksByGrAndTgGQL = gql`
  query Query($gid: ID!, $tgid: ID!) {
    getYMTasksByGrAndTg(gid: $gid, tgid: $tgid) {
      success
      id
      error
      tasks {
        id
        category
        title
        data
        status
      }
    }
  }
`;

// Mail
export const getYMMailsGQL = gql`
query Query($start: String!, $end: String!) {
  getYMMails(start: $start, end: $end) {
    error
    id
    success
    mails {
      id
      type
      tid
      key
      mailid
      emails
      status
      name
      group
      body
      requestedAt
      sentAt
      checkedAt
    }
  }
}
`;

/*
export const putMailGQL = gql`
  mutation Mutation($tid: String, $key: String, $mailid: String, $status: Int, $body: String) {
    putMail(tid: $tid, key: $key, mailid: $mailid, status: $status, body: $body) {
      data
      error
      id
      success
    }
  }
`;
*/

export const setMailGQL = gql`
  mutation Mutation($type: Int, $tid: String, $key: String, $mailid: String, $emails: String, $status: Int, $at: String, $name: String, $group: String, $body: String) {
    setMail(type: $type, tid: $tid, key: $key, mailid: $mailid, emails: $emails, status: $status, at: $at, name: $name, group: $group, body: $body) {
      data
      error
      id
      success
    }
  }
`;

export const GetYMMailsGQL = gql`
query Query($start: String!, $end: String!) {
  getYMMails(start: $start, end: $end) {
    error
    id
    success
    mails {
      body
      id
      key
      mailid
      emails
      requestedAt
      sentAt
      checkedAt
      status
      tid
    }
  }
}`;

export const allYMMailsGQL = gql`
query AllYMMails {
  allYMMails {
    id
    type
    tid
    key
    mailid
    emails
    status
    name
    group
    body
    requestedAt
    sentAt
    checkedAt
  }
}
`;

// Memory
export const allYMMemoriesGQL = gql`
query AllYMMemories {
  allYMMemories {
    id
    category
    title
    data
    owner
    willExecuteAt
    groupName
    executer
    status
    gstatus
    group
    target
    task
    tags
  }
}
`;

export const getYMMemoriesGQL = gql`
query GetYMMemories($category: String!) {
  getYMMemories(category: $category) {
    error
    id
    success
    memories {
      id
      category
      title
      data
      owner
      willExecuteAt
      groupName
      executer
      status
      gstatus
      group
      target
      task
      tags
    }
  }
}
`;

export const searchYMMemoriesGQL = gql`
query SearchYMMemories($category: String!, $keyword: String!, $value: String!) {
  searchYMMemories(category: $category, keyword: $keyword, value: $value) {
    error
    id
    memories {
      id
      category
      title
      data
      owner
      willExecuteAt
      groupName
      executer
      status
      gstatus
      group
      target
      task
      tags
    }
    success
  }
}`;


export const getYMMemoriesByCategoryAndOwnerIdGQL = gql`
  query GetYMMemoriesByCategoryAndOwnerId($ownerId: ID!, $category: String!) {
    getYMMemoriesByCategoryAndOwnerId(ownerid: $ownerId, category: $category) {
      error
      id
      success
      memories {
        id
        category
        title
        data
        owner
        willExecuteAt
        groupName
        executer
        status
        gstatus
        group
        target
        task
        tags
      }
    }
  }
`;
  
export const getYMMemoryGQL = gql`
query GetYMMemory($mid: ID!) {
  getYMMemory(id: $mid) {
    error
    id
    success
    memory {
      id
      category
      title
      data
      owner
      willExecuteAt
      groupName
      executer
      status
      gstatus
      group
      target
      task
      tags
    }
  }
}
`;

export const getYMMemoriesByGrAndTgGQL = gql`
  query Query($gid: ID!, $tgid: ID!, $category: String!) {
    getYMMemoriesByGrAndTg(gid: $gid, tgid: $tgid, category: $category) {
      success
      id
      error
      memories {
        id
        category
        title
        data
        owner
        willExecuteAt
        groupName
        executer
        status
        gstatus
        group
        target
        task
        tags
      }
    }
  }
`;

export const createYMMemoryGQL = gql`
  mutation createYMMemory($input: YMMemoryInput!) {
    createYMMemory(input: $input) {
      success
      error
      id
      data
    }
  }
`;


export const updateYMMemoryGQL = gql`
  mutation updateYMMemory($id: ID!, $input: YMMemoryInput!) {
    updateYMMemory(id: $id, input: $input) {
      success
      error
      id
      data
    }
  }
`;

export const removeYMMemoryGQL = gql`
mutation Mutation($id: ID!) {
  removeYMMemory(id: $id) {
    data
    error
    id
    success
  }
}
`;

export const updateYMTaskGQL = gql`
  mutation updateYMTask($id: ID!, $input: YMTaskInput!) {
    updateYMTask(id: $id, input: $input) {
      success
      error
      id
      data
    }
  }
`;

export const removeYMTaskGQL = gql`
mutation Mutation($id: ID!) {
  removeYMTask(id: $id) {
    data
    error
    id
    success
  }
}
`;

export const updateYMTargetGQL = gql`
  mutation Mutation($id: ID!, $input: YMTargetInput!) {
    updateYMTarget(id: $id, input: $input) {
      data
      error
      id
      success
    }
  }
`;

export const updateYMGroupGQL = gql`
  mutation Mutation($id: ID!, $input: YMGroupInput!) {
    updateYMGroup(id: $id, input: $input) {
      data
      error
      id
      success
    }
  }
`;

export const addYMTagGQL = gql`
  mutation Mutation($category: String!, $tag: YMTagInput!) {
    addYMTag(category: $category, tag: $tag) {
      id
      success
      error
    }
  }
`;

export const updateYMExecuterGQL = gql`
  mutation Mutation($id: ID!, $input: YMExecuterInput!) {
    updateYMExecuter(id: $id, input: $input) {
      data
      id
      success
      error
    }
  }
`;

export const updateYMUserGQL = gql`
  mutation UpdateYMUser($id: ID!, $input: YMUserInput!) {
    updateYMUser(id: $id, input: $input) {
      id
      data
      error
      success
    }
  }
`;

export const removeYMExecuterM = gql`
  mutation RemoveYMExecuter($removeYmExecuterId: ID!) {
    removeYMExecuter(id: $removeYmExecuterId) {
      success
      id
      error
    }
  }
`;

export const getYMTags = gql`
  query GetYMTags($category: String!) {
    getYMTags(category: $category) {
      error
      tags {
        code
        name
      }
      success
    }
  }
`;

export const allYMRoles = gql`
  query AllYMRoles {
    allYMRoles {
      name
      id
      permissions
    }
  }
`;

export const updateYMRole = gql`
  mutation UpdateYMRole($updateYmRoleId: ID!, $input: YMRoleInput!) {
    updateYMRole(id: $updateYmRoleId, input: $input) {
      id
      success
      error
    }
  }
`;

export const removeYMRole = gql`
  mutation RemoveYMRole($removeYmRoleId: ID!) {
    removeYMRole(id: $removeYmRoleId) {
      success
      id
      error
    }
  }
`;

export const createYMRole = gql`
  mutation CreateYMRole($input: YMRoleInput!) {
    createYMRole(input: $input) {
      success
      id
      error
    }
  }
`;

export const getYMUserByIdGQL = gql`
  query GetYMUserById($ymuserid: ID!) {
    getYMUserById(ymuserid: $ymuserid) {
      id
      success
      ymuser {
        targets {
          title
          id
          data
        }
        tasks {
          title
          data
          executeAt
          category
          id
        }
      }
    }
  }
`;
export const addYMTargetToYMUserM = gql`
  mutation AddYMTargetToYMUser($id: ID!, $targetid: ID!) {
    addYMTargetToYMUser(id: $id, targetid: $targetid) {
      id
      success
      error
    }
  }
`;

// {"category": "e_0,e_1,e_2,e_3,e_4"}
export const getYMTasksByCategoryGQL = gql`
query getYMTasksByCategory($category: String!) {
  getYMTasksByCategory(category: $category) {
    error
    id
    success
    tasks {
      id
      willExecuteAt
      status
      title
      group {
        data
      }
      target {
        title
      }
      data
      category
      executers {
        id
      }
    }
  }
}`;

export const getYMTasksByCategoryAndOwnerIdGQL = gql`
  query GetYMTasksByCategoryAndOwnerId($ownerId: ID!, $category: String!) {
    getYMTasksByCategoryAndOwnerId(ownerid: $ownerId, category: $category) {
      id
      success
      error
      tasks {
        willExecuteAt
        status
        data
        category
        title
        owner
        id
        executers {
          id
          user
          authorized
          name
          email
          category
          data
          targets {
            data
            category
            title
            id
            owner
          }
        }
        target {
          id
          category
          data
          title
          owner
          status
          reservedAt
        }
        group {
          id
          owner
          code
          title
          status
          category
          data
        }
      }
    }
  }
`;

export const searchYMTargetsByTaskKeywordGQL = gql`
  query SearchYMTargetsByTaskKeyword($keyword: String!, $type: String, $category: String) {
    searchYMTargetsByTaskKeyword(keyword: $keyword, type: $type, category: $category) {
      tasks {
        executedAt
        createdAt
        status
        data
        category
        title
        owner
        id
        executers {
          id
          user
          authorized
          name
          email
          category
          data
          targets {
            data
            category
            title
            id
            owner
          }
        }
        target {
          id
          category
          data
          title
          owner
          status
          reservedAt
        }
        group {
          id
          owner
          code
          title
          category
          data
        }
      }
      id
      success
    }
  }
`;

export const getYMAssessmentsGQL: TypedDocumentNode<GetYMAssessmentsData> = gql`
  query GetYMAssessments($category: String!) {
    getYMAssessments(category: $category) {
      success
      tasks {
        executedAt
        executeAt
        createdAt
        updatedAt
        status
        data
        rtdata
        category
        title
        owner
        id
        executers {
          id
          user
          authorized
          name
          email
          category
          data
          targets {
            data
            category
            title
            id
            owner
          }
        }
        target {
          id
          category
          data
          title
          owner
          status
          reservedAt
        }
        group {
          id
          owner
          code
          title
          category
          data
          status
        }
      }
      error
      id
    }
  }
`;

export const changePasswordMutation = gql`
  mutation ChangePassword($email: String!, $newPassword: String!) {
    changePassword(email: $email, newPassword: $newPassword) {
      success
      id
      socialId
    }
  }
`;
